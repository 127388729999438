import "./orderConfirmation.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Card } from "@mui/material";
import { GetOrdersUserAction } from "../../redux/action/GetUserOrdersAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { CircularProgress } from "../../components/CommonLoader";
import momenttz from "moment-timezone";

export const OrderConfirmation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const checkOutData = location?.state?.getCheckDataState;

  const paymentData = location?.state?.paymentData;
  const order_id = location?.state?.order_id;

  const getUserOrders = useSelector(
    (state) => state.GetUserOrders?.getUserOrders
  );

  const fetching = useSelector((state) => state.GetUserOrders?.fetching);

  const confirmationDetails = getUserOrders?.userDetails;

  useEffect(() => {
    dispatch(GetOrdersUserAction(order_id));
  }, []);

  const handleGoBack = () => {
    navigate("/");
  };

  const formatDate = (date) => {
    const timezone = momenttz.tz.guess();
    const centralDate = moment(date).tz(timezone);
    return centralDate.format("MM/DD/YYYY, h:mm A");
  };

  return (
    <>
      <div className="position-relative z-1">
        <Container>
          <div className="mt-5 order-confirmation-content d-flex justify-content-flex-start position-relative z-1">
            <div
              className="back-arrow d-flex align-items-center cursor-pointer"
              onClick={handleGoBack}
              style={{ width: "40px" }}
            >
              <i className="bi bi-arrow-left" style={{ fontSize: "30px" }}></i>
            </div>
            <div className="yourOrderTitle mt-2">
              <h2>Order Confirmation</h2>
            </div>
          </div>
          {fetching && order_id ? (
            <CircularProgress />
          ) : (
            <div>
              <div className="bg-white card shadow-sm rounded p-3 mt-4 mb-5 position-relative z-1">
                <div className="order-info col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-6 col-sm-12 p-4">
                    <div className="d-flex justify-content-between information-content pb-2">
                      <h4 className="confirmation-title">Your Information</h4>
                    </div>
                    <div className="mt-4">
                      <p className="text-black userName mb-1">
                        {confirmationDetails?.first_name}{" "}
                        {confirmationDetails?.last_name}
                      </p>
                      <p className="user-info">
                        {confirmationDetails?.email}, <br />
                        {confirmationDetails?.address}{" "}
                        {confirmationDetails?.address2 &&
                          `, ${confirmationDetails.address2}`}
                        {confirmationDetails?.address && ", "} <br />
                        {confirmationDetails?.city}
                        {confirmationDetails?.city && ", "} <br />
                        {confirmationDetails?.state}
                        {confirmationDetails?.state && ", "} <br />
                        {confirmationDetails?.country}
                        {confirmationDetails?.country && ", "} <br />
                        {confirmationDetails?.phone_number}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 p-4">
                    <div className="d-flex justify-content-between information-content pb-2">
                      <h4 className="confirmation-title">Shipping Address</h4>
                    </div>
                    <div className="mt-4">
                      <p className="text-black userName mb-1">
                        {paymentData?.paymentIntent?.shipping?.name}
                      </p>
                      <p className="user-info" style={{ width: "70%" }}>
                        {getUserOrders?.result?.map((e) => {
                          return e?.shippingAddress;
                        })}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="order-info col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-6 col-sm-12 p-4">
                    <div className="d-flex justify-content-between information-content pb-2">
                      <h4 className="confirmation-title">Payment</h4>
                    </div>
                    <div className="mt-4">
                      <p className="text-black userName mb-1"></p>
                      <p className="text-black userName mb-1">
                        $
                        {getUserOrders?.result?.map((e) => {
                          return Number(e?.accounting?.finalTotal).toFixed(2);
                        })}
                      </p>
                      <p className="user-info">
                        {getUserOrders?.result?.map((item) => {
                          const formattedDate = formatDate(item?.createdAt);
                          return (
                            <>
                              {formattedDate} ,<br />
                              Transaction Id: {item?._id},<br />
                            </>
                          );
                        })}
                        Payment Method :{" "}
                        <span className="text-capitalize">
                          {paymentData?.paymentIntent?.payment_method_types[0]}
                        </span>
                        ,<br />
                        <span className="text-success text-capitalize">
                          {paymentData?.paymentIntent?.status}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 p-4">
                    <div className="d-flex justify-content-between information-content pb-2">
                      <h4 className="confirmation-title">Billing Address</h4>
                    </div>
                    <div className="mt-4">
                      <p className="text-black userName mb-1">
                        {paymentData?.paymentIntent?.shipping?.name}
                      </p>
                      <p className="user-info" style={{ width: "70%" }}>
                        {getUserOrders?.result?.map((e) => {
                          return e?.billingAddress;
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 mb-5">
                <Card
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="yourCart col-12 pe-5 mb-0 mt-2">
                    {checkOutData?.cartAccountingList?.map((item, index) => {
                      return (
                        <>
                          <>
                            <div>
                              <h5 className="border-bottom pb-2">
                                {item?.vendorDetails?.first_name +
                                  " " +
                                  (item?.vendorDetails?.last_name ?? '')}
                              </h5>
                            </div>

                            {item?.products?.map((e, i) => {
                              return (
                                <>
                                  <div
                                    key={e.productId}
                                    className={`row${
                                      index <
                                      checkOutData?.cartAccountingList?.length -
                                        1
                                        ? " "
                                        : ""
                                    }`}
                                  >
                                    <div
                                      style={{
                                        flexDirection: "row",
                                        display: "flex",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={`${
                                            imageURL +
                                            e?.productDetails?.image?.[0]
                                          }`}
                                          alt="productImage"
                                          crossOrigin="anonymous"
                                          className="productImage"
                                        />
                                      </div>
                                      <div className="col-8 productDetails m-2">
                                        <div>
                                          <p className="productName">
                                            {e?.productName || "Product Name"}
                                          </p>
                                          <div className="row mt-1">
                                            <p className="col-6 item-text">
                                              <span className="productInfo">
                                                Items :{" "}
                                              </span>
                                              {e?.quantity}
                                            </p>
                                            <p className="col-6 item-text p-0">
                                              <span className="productInfo">
                                                Bean :{" "}
                                              </span>
                                              {e?.bean}
                                            </p>
                                            <p className="col-6 item-text">
                                              <span className="productInfo">
                                                Unit Price:
                                              </span>{" "}
                                              ${Number(e?.unitPrice).toFixed(2)}
                                            </p>
                                            <p className="col-6 item-text p-0">
                                              <span className="productInfo">
                                                Sub Total:
                                              </span>{" "}
                                              $
                                              {Number(e?.totalPrice).toFixed(
                                                2
                                              ) || "$ 0"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                          <div className="row pt-2 mt-2 border-top pb-2">
                            <p className="col-6">
                              <span className="shippingChargeInfo">
                                Shipping Charge :
                              </span>{" "}
                              $
                              {Number(
                                item?.vendorDetails?.shippingCost
                              ).toFixed(2) || "$0"}
                            </p>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Card>
              </div>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};
