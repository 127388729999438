import React from "react";
import { Nav, Tab } from "react-bootstrap";

const OriginsCategoryTab = ({ setOrigins, setPage }) => {
  return (
    <div>
      <Tab.Container defaultActiveKey={"blend"}>
        <Nav variant="tabs" className="mb-3">
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="blend"
              onClick={() => {
                setOrigins("Blend");
                setPage(1);
              }}
            >
              Blend
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer mx-sm-2">
            <Nav.Link
              eventKey="hondurasEthiopia"
              onClick={() => {
                setOrigins("Honduras_Ethiopia");
                setPage(1);
              }}
            >
              Honduras & Ethiopia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="peru"
              onClick={() => {
                setOrigins("Peru");
                setPage(1);
              }}
            >
              Peru
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="colombia"
              onClick={() => {
                setOrigins("Colombia");
                setPage(1);
              }}
            >
              Colombia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="guatemalaEthiopia"
              onClick={() => {
                setOrigins("Guatemala_Ethiopia");
                setPage(1);
              }}
            >
              Guatemala & Ethiopia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="latinAmericaEthiopia"
              onClick={() => {
                setOrigins("Latin America_Ethiopia");
                setPage(1);
              }}
            >
              Latin America & Ethiopia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="ethiopia"
              onClick={() => {
                setOrigins("Ethiopia");
                setPage(1);
              }}
            >
              Ethiopia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer ms-sm-2">
            <Nav.Link
              eventKey="ethiopiaColombia"
              onClick={() => {
                setOrigins("Ethiopia_Colombia");
                setPage(1);
              }}
            >
              Ethiopia & Colombia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            className="pointer ms-sm-2"
            onClick={() => {
              setOrigins("Guatemala");
              setPage(1);
            }}
          >
            <Nav.Link eventKey="guatemala">Guatemala</Nav.Link>
          </Nav.Item>
          <Nav.Item
            className="pointer ms-sm-2"
            onClick={() => {
              setOrigins("Costa Rica");
              setPage(1);
            }}
          >
            <Nav.Link eventKey="costaRica">Costa Rica</Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="special"
              onClick={() => {
                setOrigins("Special");
                setPage(1);
              }}
            >
              Special
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            className="pointer ms-sm-2"
            onClick={() => {
              setOrigins("Brazil");
              setPage(1);
            }}
          >
            <Nav.Link eventKey="brazil">Brazil</Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="Malawi"
              onClick={() => {
                setOrigins("Malawi");
                setPage(1);
              }}
            >
              Malawi
            </Nav.Link>
          </Nav.Item>
          <Nav.Item
            className="pointer ms-sm-2"
            onClick={() => {
              setOrigins("Sumatra");
              setPage(1);
            }}
          >
            <Nav.Link eventKey="Sumatra">Sumatra</Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="Honduras"
              onClick={() => {
                setOrigins("Honduras");
                setPage(1);
              }}
            >
              Honduras
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="Mexico"
              onClick={() => {
                setOrigins("Mexico");
                setPage(1);
              }}
            >
              Mexico
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="Indonesia"
              onClick={() => {
                setOrigins("Indonesia");
                setPage(1);
              }}
            >
              Indonesia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="Kenya"
              onClick={() => {
                setOrigins("Kenya");
                setPage(1);
              }}
            >
              Kenya
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="Nicaragua"
              onClick={() => {
                setOrigins("Nicaragua");
                setPage(1);
              }}
            >
              Nicaragua
            </Nav.Link>
          </Nav.Item>

        </Nav>
      </Tab.Container>
    </div>
  );
};

export default OriginsCategoryTab;
