import React, { useCallback } from "react";
import "./payment.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import SuccessModal from "../../components/SuccessOrderModal/successOrder";
import { getUserId } from "../../utils/Storage";
import { UserDetailsAction } from "../../redux/action/GetUserDetail";
import { toast } from "react-toastify";
import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  RadioGroup,
} from "@mui/material";
import { Radio } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { CircularProgress } from "../../components/CommonLoader";

export const Payment = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const cartId = location?.state?.cartId;
  const checkOutData = location?.state?.checkOutData;

  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const [showModal, setShowModal] = useState(false);
  const [newAddress, setNewAddress] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    country: "US",
    zip: "",
  });

  const [showAddress, setShowAddress] = useState(true);
  const [sameAsShipping, setSameAsShipping] = useState(true);
  const [newAddError, setNewAddError] = useState(false);
  const [newShippingError, setNewShippingError] = useState(false);
  const [newAddressOneError, setNewAddressOneError] = useState(false);
  const [newCityError, setNewCityError] = useState(false);
  const [newStateError, setNewStateError] = useState(false);
  const [newZipError, setNewZipError] = useState(false);

  const [newBillingAddressOneError, setNewBillingAddressOneError] =
    useState(false);
  const [newBillingCityError, setNewBillingCityError] = useState(false);
  const [newBillingStateError, setNewBillingStateError] = useState(false);
  const [newBillingZipError, setNewBillingZipError] = useState(false);

  const [billingAddress, setBillingAddress] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    country: "US",
    zip: "",
  });
  const [oldAddress, setOldAddress] = useState({
    addressOne: "",
    city: "",
    addressTwo: "",
    state: "",
    country: "US",
    zip: "",
  });

  const paymentKey = process.env.REACT_APP_STRIPE_KEY;

  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.GetUserDetail.userGetData);

  useEffect(() => {
    setOldAddress({
      addressOne: userDetails?.address,
      addressTwo: userDetails?.address2,
      city: userDetails?.city,
      state: userDetails?.state,
      country: userDetails?.country,
      zip: userDetails?.pincode,
    });
  }, [userDetails]);

  const fetching = useSelector((state) => state.getCheckOutData?.fetching);

  const userId = getUserId();

  const stripePromise = loadStripe(paymentKey);

  const handleUserDetail = (id) => dispatch(UserDetailsAction(id));

  useEffect(() => {
    handleUserDetail(userId);
  }, []);

  const handleCheckboxChange = () => {
    setSameAsShipping(!sameAsShipping);
    if (!sameAsShipping) {
      setBillingAddress("");
      setNewShippingError(false);
    } else {
      setNewShippingError(true);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const stateOptions = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    if (
      !showAddress &&
      newAddress?.addressOne &&
      newAddress?.city &&
      newAddress?.state &&
      newAddress?.zip
    ) {
      setNewAddError(false);
    } else if (!showAddress) {
      setNewAddError(true);
    }
  }, [showAddress, newAddress]);

  useEffect(() => {
    if (
      !sameAsShipping &&
      billingAddress?.addressOne &&
      billingAddress?.city &&
      billingAddress?.state &&
      billingAddress?.zip
    ) {
      setNewShippingError(false);
    } else if (!sameAsShipping) {
      setNewShippingError(true);
    }
  }, [sameAsShipping, billingAddress]);

  const formatAddress = (addressObj) => {
    const { addressOne, addressTwo, city, state, country, zip } = addressObj;
    let formattedAddress = "";

    if (addressOne && addressOne.length) {
      formattedAddress += `${addressOne}, `;
    }
    if (addressTwo && addressTwo.length) {
      formattedAddress += `${addressTwo}, `;
    }
    if (city && city.length) {
      formattedAddress += `${city}, `;
    }
    if (state && state.length) {
      formattedAddress += `${state}, `;
    }
    if (country && country.length) {
      formattedAddress += `${country}, `;
    }
    if (zip && zip.length) {
      formattedAddress += `${zip}`;
    }

    if (formattedAddress.endsWith(", ")) {
      formattedAddress = formattedAddress.slice(0, -2);
    }

    return formattedAddress;
  };

  // New Address
  const handleNewAddressData = useCallback(
    (e) => {
      if (!showAddress) {
        const { name, value } = e.target;
        setNewAddress({ ...newAddress, [name]: value });
        switch (name) {
          case "addressOne":
            if (value.length >= 2) {
              setNewAddressOneError(false);
            }
            break;
          case "city":
            if (value.length >= 2) {
              setNewCityError(false);
            }
            break;
          case "state":
            if (value.length >= 2) {
              setNewStateError(false);
            }
            break;
          case "zip":
            if (value.length >= 2) {
              setNewZipError(false);
            }
            break;
          default:
            break;
        }
      }
    },
    [newAddress]
  );

  // New Billing Address
  const handleNewBillingAddressData = useCallback(
    (e) => {
      if (!sameAsShipping) {
        const { name, value } = e.target;
        setBillingAddress({ ...billingAddress, [name]: value });
        switch (name) {
          case "addressOne":
            if (value.length >= 2) {
              setNewBillingAddressOneError(false);
            }
            break;
          case "city":
            if (value.length >= 2) {
              setNewBillingCityError(false);
            }
            break;
          case "state":
            if (value.length >= 2) {
              setNewBillingStateError(false);
            }
            break;
          case "zip":
            if (value.length >= 5) {
              setNewBillingZipError(false);
            }
            break;
          default:
            break;
        }
      }
    },
    [billingAddress, sameAsShipping]
  );

  const checkError = () => {
    // New Address
    if (!showAddress && !newAddress?.addressOne) {
      setNewAddressOneError(true);
    }
    if (!showAddress && !newAddress?.city) {
      setNewCityError(true);
    }
    if (!showAddress && !newAddress?.state) {
      setNewStateError(true);
    }
    if (!showAddress && !newAddress?.zip) {
      setNewZipError(true);
    }
    // Billing Address
    if (!sameAsShipping && !billingAddress?.addressOne) {
      setNewBillingAddressOneError(true);
    }
    if (!sameAsShipping && !billingAddress?.city) {
      setNewBillingCityError(true);
    }
    if (!sameAsShipping && !billingAddress?.state) {
      setNewBillingStateError(true);
    }
    if (!sameAsShipping && !billingAddress?.zip) {
      setNewBillingZipError(true);
    }
  };
  return (
    <>
      <div className="paymentCart position-relative z-1">
        <div className="d-flex mb-3 mt-3">
          <div
            className="back-arrow d-flex align-items-center mt-2"
            onClick={handleGoBack}
            style={{ width: "40px" }}
          >
            <i className="bi bi-arrow-left" style={{ fontSize: "30px" }}></i>
          </div>
          <div className="PaymentTitle mt-3">
            <h2>Checkout</h2>
          </div>
        </div>
        <div className="divider" />
        {fetching ? (
          <CircularProgress />
        ) : checkOutData ? (
          <div className="d-flex justify-content-center col-12 mt-3 flexPayment">
            {/* Product List Start */}
            <div className="productData col-5 pe-2 overflow-hidden">
              <div className="yourCart col-12 pe-5">
                {checkOutData?.cartAccountingList?.map((item, index) => {
                  return (
                    <>
                      <>
                        <div>
                          <h5 className="border-bottom pb-2">
                            {item?.vendorDetails?.first_name +
                              " " +
                              (item?.vendorDetails?.last_name ?? '')}
                          </h5>
                        </div>

                        {item?.products?.map((e, i) => {
                          let displayDetails = e?.productDetails;

                          if (
                            e?.productDetails?.status === "P" &&
                            e?.productDetails?.oldDetails?.status === "A"
                          ) {
                            displayDetails = e?.productDetails?.oldDetails;
                          }

                          return (
                            <div
                              key={e.productId}
                              className={`row${
                                index <
                                checkOutData?.cartAccountingList?.length - 1
                                  ? " "
                                  : ""
                              }`}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <div>
                                  <img
                                    src={`${
                                      imageURL + displayDetails?.image?.[0]
                                    }`}
                                    alt="productImage"
                                    crossOrigin="anonymous"
                                    className="productImage"
                                  />
                                </div>
                                <div className="col-8 productDetails m-2">
                                  <div>
                                    <p className="productName">
                                      {e?.productName || "Product Name"}
                                    </p>
                                    <div className="row mt-1">
                                      <p className="col-6 item-text">
                                        <span className="productInfo">
                                          Items :{" "}
                                        </span>
                                        {e?.quantity}
                                      </p>
                                      <p className="col-6 item-text">
                                        <span className="productInfo">
                                          Bean :{" "}
                                        </span>
                                        {e?.bean}
                                      </p>
                                      <p className="col-6 item-text">
                                        <span className="productInfo">
                                          Unit Price:
                                        </span>{" "}
                                        $
                                        {Number(displayDetails?.total_price).toFixed(
                                          2
                                        )}
                                      </p>
                                      {/* <p className="col-6 item-text p-0">
                                        <span className="productInfo">
                                          Sub Total:
                                        </span>{" "}
                                        $
                                        {Number(
                                          displayDetails?.total_price
                                        ).toFixed(2) || "$ 0"}
                                      </p> */}
                                      <p className="col-6 item-text">
                                        <span className="productInfo">
                                          Weight :{" "}
                                        </span>
                                        {displayDetails?.weight}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                      <div className="row pt-2 mt-2 border-top pb-2">
                        <p className="col-6">
                          <span className="shippingChargeInfo">
                            Shipping Charge :
                          </span>{" "}
                          $
                          {Number(item?.vendorDetails?.shippingCost).toFixed(
                            2
                          ) || "$0"}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <p className="line col-1"></p>
            <div className="container subView col-6 mt-3">
              <div>
                <p className="productName mb-2">Shipping Address :</p>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={showAddress ? "existing" : "new"}
                  onChange={(event) => {
                    setShowAddress(event.target.value === "existing");
                    if (event.target.value === "new") {
                      setNewAddError(true);
                      setNewAddress({
                        addressOne: "",
                        addressTwo: "",
                        city: "",
                        state: "",
                        country: "US",
                        zip: "",
                      });
                    } else {
                      setNewAddError(false);
                    }
                  }}
                >
                  <FormControlLabel
                    value="existing"
                    control={<Radio />}
                    label={`${userDetails?.address}, ${userDetails?.city}, ${userDetails?.state}, ${userDetails?.country}, ${userDetails?.pincode}`}
                    className="mb-0"
                  />
                  <FormControlLabel
                    value="new"
                    control={<Radio />}
                    label="Use Different Address"
                    className="mt-0 mb-1"
                  />
                </RadioGroup>
                {/* Add New Address */}
                {showAddress ? null : (
                  <div className="row">
                    <div className="mb-1 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                      <label for="addressOne" className="form-label">
                        Address Line 1
                      </label>
                      <input
                        value={newAddress?.addressOne}
                        className="form-control"
                        onChange={handleNewAddressData}
                        name="addressOne"
                        type="text"
                        id="addressOne"
                        placeholder="Address Line 1"
                      />
                      {newAddressOneError && (
                        <p className="error-text">Please Enter Address</p>
                      )}
                    </div>
                    <div className="mb-1 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                      <label for="addressTwo" className="form-label">
                        Address Line 2
                      </label>
                      <input
                        value={newAddress?.addressTwo}
                        className="form-control"
                        onChange={handleNewAddressData}
                        name="addressTwo"
                        id="addressTwo"
                        placeholder="Address Line 2"
                      />
                    </div>
                    <div className="mb-1 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <label for="city" className="form-label">
                        City
                      </label>
                      <input
                        value={newAddress?.city}
                        className="form-control"
                        onChange={handleNewAddressData}
                        name="city"
                        id="city"
                        placeholder="City"
                      />
                      {newCityError && (
                        <p className="error-text">Please Enter City</p>
                      )}
                    </div>
                    <div className="mb-1 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <label htmlFor="stateSelect" className="form-label">
                        State
                      </label>
                      <select
                        className="form-select"
                        onChange={handleNewAddressData}
                        name="state"
                        id="stateSelect"
                        value={newAddress?.state}
                      >
                        <option value="State" label="State">
                          {newAddress?.state}
                        </option>
                        {stateOptions.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      {newStateError && (
                        <p className="error-text">Please Enter State</p>
                      )}
                    </div>
                    <div className="mb-1 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
                      <label for="country" className="form-label">
                        Country
                      </label>
                      <input
                        value={"US"}
                        className="form-control"
                        name="country"
                        id="country"
                        placeholder="Country"
                        disabled
                      />
                    </div>
                    <div className="mb-1 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <label for="zip" className="form-label">
                        ZIP
                      </label>
                      <input
                        value={newAddress?.zip}
                        className="form-control"
                        onChange={handleNewAddressData}
                        name="zip"
                        id="zip"
                        placeholder="Zip Code"
                        inputMode="numeric"
                        maxLength={5}
                      />
                      {newZipError && (
                        <p className="error-text">Please Enter ZIP</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* New Billing Address */}
              <div className="linev mt-4 mb-3" />
              <div className="mt-4">
                <p className="productName mb-4">Billing Address :</p>
                <Checkbox
                  checked={sameAsShipping}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  className="p-0"
                />
                <span className="shippingInfo p-2">
                  Same As Shipping Address
                </span>
                {sameAsShipping ? null : (
                  <div className="row">
                    <div className="mb-1 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                      <label for="billingAddressOne" className="form-label">
                        Address Line 1
                      </label>
                      <input
                        value={billingAddress?.addressOne}
                        className="form-control"
                        onChange={handleNewBillingAddressData}
                        name="addressOne"
                        id="addressOne"
                        placeholder="Address Line 1"
                      />
                      {newBillingAddressOneError && (
                        <p className="error-text">Please Enter Address</p>
                      )}
                    </div>
                    <div className="mb-1 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 ">
                      <label for="billingAddressTwo" className="form-label">
                        Address Line 2
                      </label>
                      <input
                        value={billingAddress?.addressTwo}
                        className="form-control"
                        onChange={handleNewBillingAddressData}
                        name="addressTwo"
                        id="billingAddressTwo"
                        placeholder="Address Line 2"
                      />
                    </div>
                    <div className="mb-1 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <label for="billingCity" className="form-label">
                        City
                      </label>
                      <input
                        value={billingAddress?.city}
                        className="form-control"
                        onChange={handleNewBillingAddressData}
                        name="city"
                        id="city"
                        placeholder="City"
                      />
                      {newBillingCityError && (
                        <p className="error-text">Please Enter City</p>
                      )}
                    </div>
                    <div className="mb-1 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <label
                        htmlFor="billingStateSelect"
                        className="form-label"
                      >
                        State
                      </label>
                      <select
                        className="form-select"
                        onChange={handleNewBillingAddressData}
                        name="state"
                        id="state"
                        value={billingAddress.state}
                      >
                        <option value="State" label="State">
                          {billingAddress?.state}
                        </option>
                        {stateOptions.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      {newBillingStateError && (
                        <p className="error-text">Please Enter State</p>
                      )}
                    </div>
                    <div className="mb-1 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
                      <label for="billingCountry" className="form-label">
                        Country
                      </label>
                      <input
                        value={"US"}
                        className="form-control"
                        name="billingCountry"
                        id="billingCountry"
                        placeholder="Country"
                        disabled
                      />
                    </div>
                    <div className="mb-1 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                      <label for="zip" className="form-label">
                        ZIP
                      </label>
                      <input
                        value={billingAddress?.zip}
                        className="form-control"
                        onChange={handleNewBillingAddressData}
                        name="zip"
                        id="zip"
                        maxLength={5}
                        placeholder="Zip"
                        inputMode="numeric"
                      />
                      {newBillingZipError && (
                        <p className="error-text">Please Enter ZIP</p>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="linev mt-4 mb-3" />
              {/* Payment */}
              <p className="productName mb-4">Total Cost :</p>
              <div className="totalView">
                <p className="d-flex mb-3 justify-content-between align-items-center">
                  <span>Shipping Charge: </span>
                  <span className="productName">
                    ${Number(checkOutData?.shippingCost).toFixed(2)}
                  </span>
                </p>

                <p className="d-flex mb-3 justify-content-between align-items-center">
                  <span>Sub Total: </span>
                  <span className="productName">
                    $
                    {Number(Number(checkOutData?.finalTotal?.toFixed(2)) - Number(checkOutData?.shippingCost?.toFixed(2))).toFixed(2)}
                  </span>
                </p>

                <p className="d-flex mb-3 justify-content-between align-items-center">
                  <span>Total: </span>
                  <span className="productName">
                    ${Number(checkOutData?.finalTotal).toFixed(2)}
                  </span>
                </p>

                <div className="linev mt-4 mb-3" />

                <p className="productName mb-4">Payment Info :</p>
                <div className="position-relative">
                  {(newAddError || newShippingError) && (
                    <button
                      className="btn btn-danger before_payment"
                      onClick={checkError}
                    >
                      btn
                    </button>
                  )}
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      clientSecret={`${checkOutData?.paymentId}`}
                      getCheckDataState={checkOutData}
                      orderId={checkOutData?.order_id}
                      setShowModal={setShowModal}
                      newAddress={
                        !showAddress
                          ? formatAddress(newAddress)
                          : formatAddress(oldAddress)
                      }
                      billingAddress={
                        !sameAsShipping
                          ? formatAddress(billingAddress)
                          : !showAddress
                          ? formatAddress(newAddress)
                          : formatAddress(oldAddress)
                      }
                      sameAsShipping={sameAsShipping}
                      newAddError={newAddError || newShippingError}
                      userDetails={userDetails}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="emptyCartMessage">
            <p>Your cart is empty. Add items to your cart to proceed.</p>
          </div>
        )}
      </div>
      {showModal && (
        <SuccessModal show={showModal} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};
