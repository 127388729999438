import { apiInstance } from "../../httpclient";
import { getToken } from "../../utils/Storage";
import * as actions from "./index";

export const GetVendorAction = (pageNumber) => (dispatch) => {
  const token = getToken();
  new Promise((resolve, reject) => {
    dispatch(actions.getVendorRequest());
    apiInstance
      .get(`vendor/all?pageNumber=${pageNumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        dispatch(actions.getVendorSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getVendorError(error?.response?.message));
        reject(error);
      });
  });
};
