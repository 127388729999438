import React from "react";
import "../../assets/css/style.css";
import footerLogo from "../../assets/images/footer-logo.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  const centerText = { textAlign: "center" };

  return (
    <footer className="main-footer">
      <div className="auto-container position-relative z-1">
        <div className="widgets-section">
          <div className="row clearfix d-flex justify-content-center">
            <div
              className="footer-column col-lg-4 col-md-6 col-sm-12"
              style={centerText}
            >
              <div className="footer-widget lists-widget">
                <div className="footer-title">
                  {/* social-icon-two */}
                  <h2>Quick Links</h2>
                </div>
                <div className="widget-content">
                  <ul className="footer-list ps-0">
                    <li>
                      <Link to="about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/our-products">Our Products</Link>
                    </li>
                    <li>
                      <Link to="/policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/return_policy">Return Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="footer-column col-lg-4 col-md-12 col-sm-12"
              style={centerText}
            >
              <div className="footer-widget logo-widget">
                <div className="logo">
                  <Link to={"/"}>
                    <img src={footerLogo} alt="" />
                  </Link>
                  {/* <h4 className="footer-heading">coffee with a purpose</h4> */}
                  <li className="mt-2"> Powered by Dr Jack's Coffee</li>
                </div>
              </div>
            </div>

            <div
              className="footer-column col-lg-4 col-md-6 col-sm-12"
              style={centerText}
            >
              <div className="footer-widget lists-widget">
                <div className="footer-title">
                  <h2>Connect With Us</h2>
                </div>
                <div className="widget-content">
                  <ul className="social-icon-two ps-0">
                    <li>
                      <a href="https://twitter.com/DrJacksCoffee">
                        <span className="fa fa-twitter"></span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/drjacks.coffee/?hl=en">
                        <span className="fa fa-instagram"></span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100088681746660">
                        <span className="fa fa-facebook"></span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/dr-jacks-coffee-house/?viewAsMember=true">
                        <span className="fa fa-linkedin"></span>
                      </a>
                    </li>
                  </ul>
                  <div className="mx-auto text-center footer-text">
                    Ouachita Baptist University <br /> 410 Ouachita St. <br />{" "}
                    Arkadelphia, AR 71923
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
