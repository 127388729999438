import React from "react";
import { Nav, Tab } from "react-bootstrap";

const RoastCategoryTab = ({ setRoast, setPage }) => {
  return (
    <div>
      <Tab.Container defaultActiveKey={"Medium"}>
        <Nav variant="tabs" className="mb-3">
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="Medium"
              onClick={() => {
                setRoast("Medium");
                setPage(1);
              }}
            >
              Medium
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer mx-sm-2">
            <Nav.Link
              eventKey="Espresso"
              onClick={() => {
                setRoast("Espresso");
                setPage(1);
              }}
            >
              Espresso
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer mx-sm-2">
            <Nav.Link
              eventKey="Light Roast"
              onClick={() => {
                setRoast("Light Roast");
                setPage(1);
              }}
            >
              Light Roast
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer mx-sm-2">
            <Nav.Link
              eventKey="Medium Roast"
              onClick={() => {
                setRoast("Medium Roast");
                setPage(1);
              }}
            >
              Medium Roast
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer mx-sm-2">
            <Nav.Link
              eventKey="Espresso_Dark"
              onClick={() => {
                setRoast("Espresso_Dark");
                setPage(1);
              }}
            >
              Espresso & Dark
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer mx-sm-2">
            <Nav.Link
              eventKey="Dark Roast"
              onClick={() => {
                setRoast("Dark Roast");
                setPage(1);
              }}
            >
              Dark Roast
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="special"
              onClick={() => {
                setRoast("Special");
                setPage(1);
              }}
            >
              Special
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="medium/Dark"
              onClick={() => {
                setRoast("Medium/Dark");
                setPage(1);
              }}
            >
              Medium/Dark
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="medium/Light"
              onClick={() => {
                setRoast("Medium/Light");
                setPage(1);
              }}
            >
              Medium/Light
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="expresso/Medium"
              onClick={() => {
                setRoast("Expresso/Medium");
                setPage(1);
              }}
            >
              Expresso/Medium
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="decaf"
              onClick={() => {
                setRoast("Decaf");
                setPage(1);
              }}
            >
              Decaf
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="pointer me-sm-2">
            <Nav.Link
              eventKey="pods"
              onClick={() => {
                setRoast("Pods");
                setPage(1);
              }}
            >
              Pods
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
    </div>
  );
};

export default RoastCategoryTab;
