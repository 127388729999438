import React, { useEffect, useState } from "react";
import Partner1 from "../../assets/images/partner/1.jpg";
import { Link } from "react-router-dom";
import { GetVendorAction } from "../../redux/action/GetVendorAction";
import { useDispatch, useSelector } from "react-redux";
import { LoaderSvg } from "../../assets/AllSvgs";
import Loader from "react-js-loader";
import InfiniteScroll from "react-infinite-scroll-component";
import "./partner.css";

export const Partner = () => {
  const dispatch = useDispatch();
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const [loaderPage, setLoaderPage] = useState(false);
  const [page, setPage] = useState(1);

  const GetAllVendor = (pageNumber) =>
    dispatch(GetVendorAction(pageNumber, () => setLoaderPage(false)));

  const getAllVendor = useSelector(
    (state) => state?.getAllVendor?.vendorGetAllData
  );

  const fetching = useSelector((state) => state.getAllVendor.fetching);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await GetAllVendor(page);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [page]);

  return (
    <section className="awards-section">
      <div className="container">
        <div className="sec-title style-two">
          <div className="title-column">
            <h2>Our Partners</h2>
          </div>
        </div>

        <div className="row clearfix">
          {fetching && !loaderPage ? (
            <Loader />
          ) : (
            <InfiniteScroll
              className="hide-scroll"
              dataLength={getAllVendor?.result?.length ?? 0}
              next={() => {
                if (getAllVendor?.meta?.nextPage) {
                  setLoaderPage(true);
                  setPage(page + 1);
                }
              }}
              hasMore={getAllVendor?.meta?.nextPage}
              loader={<LoaderSvg width={50} height={50} />}
            >
              <div className="row">
                {getAllVendor?.result?.map((data, index) => (
                  <div
                    key={index}
                    className="award-block col-lg-3 col-md-4 col-sm-6 col-12 wow fadeIn animated vendor-box"
                    style={{
                      visibility: "visible",
                      animationName: "fadeIn",
                    }}
                  >
                    <Link
                      to={data?.website}
                      target="_blank"
                      className="d-block"
                    >
                      <div className="inner-box vender">
                        <div className="image">
                          <img
                            src={
                              data.image && data.image[0]
                                ? imageURL + data.image[0]
                                : Partner1
                            }
                            crossOrigin="anonymous"
                            alt="partner"
                            className="w-100 h-100 object-fit-cover"
                          />
                        </div>
                        <div className="caption-box mt-2">
                          <h3 className="categoryName">
                            {data?.first_name +
                              (data?.last_name?.length > 0
                                ? " " + data?.last_name
                                : "")}
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </section>
  );
};
